import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface LookupResult {
  value: any | undefined;
  name: string | null | undefined;
}

export type LookupResults = LookupResult[];

export type LookupApiResponse = any[];

export type Lookup =
  | 'entity-status'
  | 'industries'
  | 'languages'
  | 'professions'
  | 'pricing-plan'
  | 'stripe-subscriptions-statuses'
  | 'groups'
  | 'states'
  | 'offsite-client'
  | 'organization'
  | 'cooperative'
  | 'org-coop'
  | 'activeOrganization'
  | 'org-roles'
  | 'roles'
  | 'entity-user-invite-status'
  | 'courses'
  | 'courses-filtered'
  | 'course-training'
  | 'course-states'
  | 'course-versions'
  | 'trainings'
  | 'learner-status'
  | 'certificate-status'
  | 'user-scormtrack-status'
  | 'scorm-types'
  | 'modules'
  | 'learning-path'
  | 'privacy-request-types'
  | 'cert-files'
  | 'badge-files'
  | 'ui-notification-types'
  | 'site-pages'
  | 'org-purchase-types'
  | 'user-opt-out-notification-types';

@Injectable({
  providedIn: 'root',
})
export class LookupsService extends BaseAPI {
  public userScormtrackStatus() {
    return this.lookup('user-scormtrack-status');
  }

  private lookup(lookup: Lookup): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      })
    );
  }

  private lookupStates(lookup: Lookup): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { key: item.key, name: item.text, value: item.abbr };
        });
      })
    );
  }

  private entityLookup(lookup: Lookup, searchTerm: any): Observable<LookupResult[]> {
    return this.http
      .get<LookupApiResponse>(`LookUp/${lookup}`, {
        params: { searchTerm: searchTerm },
      })
      .pipe(
        map((res) => {
          return res.map((item) => {
            return { value: item.key, name: item.text };
          });
        })
      );
  }

  // Search for active org or coop only, skipping archived ones
  private activeEntityLookup(lookup: Lookup, searchTerm: any): Observable<LookupResult[]> {
    var statusId = '3'; // Archived status
    return this.http
      .get<LookupApiResponse>(`LookUp/${lookup}/${statusId}`, {
        params: { searchTerm: searchTerm},
      })
      .pipe(
        map((res) => {
          return res.map((item) => {
            return { value: item.key, name: item.text };
          });
        })
      );
  }

  private rolesLookup(lookup: Lookup): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.id, name: item.name };
        });
      })
    );
  }

  private groupLookup(lookup: Lookup, entityGuid: any): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}/${entityGuid}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      })
    );
  }

  private courseVersionLookup(lookup: Lookup, trainingId: number): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}/${trainingId}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      })
    );
  }

  private courseTrainingLookup(lookup: Lookup, trainingId: number): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}/${trainingId}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      })
    );
  }

  private coursesFilteredLookup(trainingId: number, stateId: number): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/courses-filtered/${trainingId}/${stateId}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      })
    );
  }

  // used directly in the component
  public lookupProfessions(industryId: number): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/professions/${industryId}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      }),
    );
  }

  // used directly in the component
  public lookupJobTitles(professionId: number): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/job-titles/${professionId}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      }),
    );
  }

  private lookupCourseStates(courseId: number): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/course-states/${courseId}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      }),
    );
  }

  public entityStatus() {
    return this.lookup('entity-status');
  }
  public industries() {
    return this.lookup('industries');
  }
  public languages() {
    return this.lookup('languages');
  }
  public pricingPlans() {
    return this.lookup('pricing-plan');
  }
  public stripeSubscriptionStatus() {
    return this.lookup('stripe-subscriptions-statuses');
  }
  public states() {
    return this.lookup('states');
  }
  public courses() {
    return this.lookup('courses');
  }
  public coursesFiltered(trainingId: number, stateId: number) {
    return this.coursesFilteredLookup(trainingId, stateId);
  }
  public courseTrainings(trainingId: number) {
    return this.courseTrainingLookup('course-training', trainingId);
  }
  public courseStates() {
    return this.lookupStates('states');
  }
  public courseStatesByCourseId(courseId: number) {
    return this.lookupCourseStates(courseId);
  }
  public courseVersions(trainingId: number) {
    return this.courseVersionLookup('course-versions', trainingId);
  }
  public trainings() {
    return this.lookup('trainings');
  }
  public learnerStatus() {
    return this.lookup('learner-status');
  }
  public offsiteClient(searchT: any) {
    return this.entityLookup('offsite-client', searchT);
  }
  public organization(searchT: any) {
    return this.entityLookup('organization', searchT);
  }
  public activeOrganization(searchT: any) {
    // Fetch only active organizations skipping archived ones
    return this.activeEntityLookup('organization', searchT);
  }
  public cooperative(searchT: any) {
    return this.entityLookup('cooperative', searchT);
  }
  public activeCooperative(searchT: any) {
    // Fetch only active cooperatives skipping archived ones
    return this.activeEntityLookup('cooperative', searchT);
  }
  public orgCoop(searchT: any) {
    return this.entityLookup('org-coop', searchT);
  }
  public activeOrgCoop(searchT: any) {
    // Fetch only active Organizations & cooperatives skipping archived ones
    return this.activeEntityLookup('org-coop', searchT);
  }
  public roles() {
    return this.rolesLookup('roles');
  }
  public orgRoles() {
    return this.lookup('org-roles');
  }
  public orgUserInvitesStatus() {
    return this.lookup('entity-user-invite-status');
  }
  public group(entityGuid: any) {
    return this.groupLookup('groups', entityGuid);
  }
  public scormTypes() {
    return this.lookup('scorm-types');
  }
  public modules() {
    return this.lookup('modules');
  }
  public learningPath() {
    return this.lookup('learning-path');
  }
  public privacyRequestTypes() {
    return this.lookup('privacy-request-types');
  }
  public certFiles() {
    return this.lookup('cert-files');
  }
  public badgeFiles() {
    return this.lookup('badge-files');
  }
  public uiNotificationTypes() {
    return this.lookup('ui-notification-types');
  }
  public sitePages() {
    return this.lookup('site-pages');
  }
  public orgPurchaseTypes() {
    return this.lookup('org-purchase-types');
  }
  public userOptOutNotificationTypes() {
    return this.lookup('user-opt-out-notification-types');
  }
}
