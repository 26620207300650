<mat-nav-list role="navigation" appAccordion  aria-label="Main navigation" >
  <mat-list-item
  appAccordionLink
  [sidebarOpen]="true"
  [expandOnLoad]="true"
  group="{{ menuitem.state }}"
  *ngFor="let menuitem of mi$ | async; let i = index"
  selectItem="{{ menuitem?.type }}"
  class="hover:bg-transparent mb-2 tour-{{ menuitem?.name }} "
> 

    <a
      class="flex items-center py-md px-md w-full "
      appAccordionToggle
      *ngIf="menuitem?.type === ''"
      [routerLink]="menuitem?.state"
      routerLinkActive="selected"
    >
    <mat-icon>{{ menuitem?.icon }}</mat-icon>
    <span>{{ menuitem?.name }}</span>
    <span class="flex-1"></span>
    <span class="label label-{{ badge?.type }}" *ngFor="let badge of menuitem?.badge">{{ badge?.value }}</span>
    </a>

    <a
      class="flex items-center custom-dropdown py-md px-md w-full"
      *ngIf="menuitem?.type === 'sub'"
      appAccordionToggle
    >
      <mat-icon>{{ menuitem?.icon }}</mat-icon>
      <span>{{ menuitem?.name }}</span>
      <span class="flex-1"></span>
      <span class="label label-{{ badge?.type }}" *ngFor="let badge of menuitem?.badge">{{ badge?.value }}</span>
      <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
    </a>
    <mat-nav-list role="navigation" class="sub-item" *ngIf="menuitem?.type === 'sub'"  [attr.aria-label]="menuitem?.name + ' navigation'">
      <mat-list-item *ngFor="let childitem of menuitem.children; let j = index" routerLinkActive="selected"
    >
        <a
          [routerLink]="menuitem?.state ? ['/', menuitem?.state, childitem?.state] : ['/', childitem?.state]"
          *ngIf="childitem?.type === 'link'"
          class="relative "
          routerLinkActive="selected"
          (click)="itemSelect[childitem] = j"
          >{{ childitem?.name }}</a

          >
      </mat-list-item>
    </mat-nav-list>
    <div class="saperator text-muted" *ngIf="menuitem?.type === 'saperator'">
      <span>{{ menuitem.name }}</span>
    </div>
  </mat-list-item>
</mat-nav-list> 