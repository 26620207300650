import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/routes/authentication/state/auth.service';
import { Menu, MenuItems } from '../menu-items/menu-items';



@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  status = true;
  
  itemSelect: number[] = [];
  parentIndex = 0;
  childIndex = 0;
  mi$!: Observable<Menu[]>; // Observable for menu items
  @Output() openSidebar = new EventEmitter();
  @Input() sidebarOpened!: boolean;
  menuChildren!: any;
  sideMenu: any[] = [];
  eventEmitter: any;

  // Track the selected menu item
  setClickedRow(i: number, j: number) {
    this.parentIndex = i;
    this.childIndex = j;
  }

  subclickEvent() {
    this.status = true;
  }

  scrollToTop() {
    document.querySelector('.page-wrapper')?.scroll({
      top: 0,
      left: 0,
    });
  }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private authService: AuthService, 
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    // Fetch the menu items from the service
    this.mi$ = this.menuItems.getMenuitem();
    this.mi$.subscribe();
  }


  logout() {
    this.authService.logout();
  }
}
